import React, { useState, useMemo } from 'react';
import { usePDF } from 'react-to-pdf';

function GameStats({ game, onUpdateStats, onDeleteGame }) {
  const stats = useMemo(() => ['goals', 'assists'], []);
  const { toPDF, targetRef } = usePDF({ filename: `${game.name}_stats.pdf` });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [opponentScore, setOpponentScore] = useState(game.opponent_score || 0);

  const handleDeleteGame = () => {
    if (window.confirm(`Are you sure you want to delete the game "${game.name}"? This action cannot be undone.`)) {
      onDeleteGame(game.id);
    }
  };

  // Sorting function and playerStats initialization
  const { sortedPlayerStats, totals } = useMemo(() => {
    const playerStats = game.playerStats || [];
    let sortableItems = [...playerStats];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === 'name') {
          if (a.player?.name < b.player?.name) return sortConfig.direction === 'ascending' ? -1 : 1;
          if (a.player?.name > b.player?.name) return sortConfig.direction === 'ascending' ? 1 : -1;
          return 0;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
          if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
          return 0;
        }
      });
    }

    // Calculate totals
    const totals = sortableItems.reduce((acc, playerStat) => {
      stats.forEach(stat => {
        acc[stat] = (acc[stat] || 0) + (playerStat[stat] || 0);
      });
      return acc;
    }, {});

    return { sortedPlayerStats: sortableItems, totals };
  }, [game.playerStats, sortConfig, stats]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleOpponentScoreChange = (change) => {
    const newScore = Math.max(0, opponentScore + change);
    setOpponentScore(newScore);
    onUpdateStats(game.id, null, 'opponent_score', newScore);
  };

  return (
    <div className="mb-8 p-4 sm:p-6 rounded-lg bg-white dark:bg-gray-800 shadow-md">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <h2 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-0">{game.name}</h2>
        <div className="flex gap-2">
          <button 
            onClick={toPDF}
            className="px-2 py-1 rounded bg-green-500 text-white hover:bg-green-600 transition-colors text-sm"
          >
            Export PDF
          </button>
          <button 
            onClick={handleDeleteGame}
            className="px-2 py-1 rounded bg-red-500 text-white hover:bg-red-600 transition-colors text-sm"
          >
            Delete Game
          </button>
        </div>
      </div>
      <div className="overflow-x-auto" ref={targetRef}>
        <table className="w-full text-sm sm:text-base">
          <thead>
            <tr>
              <th className="text-left cursor-pointer" onClick={() => requestSort('name')}>
                Player {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
              </th>
              {stats.map(stat => (
                <th key={stat} className="text-center cursor-pointer" onClick={() => requestSort(stat)}>
                  {stat.charAt(0).toUpperCase() + stat.slice(1)} {sortConfig.key === stat && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedPlayerStats.map(playerStat => (
              <tr key={`${game.id}-${playerStat.player_id}`}>
                <td className="truncate max-w-[100px] sm:max-w-none text-xs md:text-sm">{playerStat.player?.name || 'Unknown Player'}</td>
                {stats.map(stat => (
                  <td key={`${game.id}-${playerStat.player_id}-${stat}`} className="text-center">
                    <span className="mx-1 sm:mx-2 w-6 inline-block">{playerStat[stat]}</span>
                    <button 
                      onClick={() => onUpdateStats(game.id, playerStat.player_id, stat, playerStat[stat] + 1)}
                      className="px-3 sm:px-4 py-2 rounded bg-green-500 text-white hover:bg-green-600 transition-colors text-xs sm:text-sm"
                    >
                      +
                    </button>
                    <button 
                      onClick={() => onUpdateStats(game.id, playerStat.player_id, stat, Math.max(0, playerStat[stat] - 1))}
                      className="ml-1 sm:ml-2 px-3 sm:px-4 py-2 rounded bg-yellow-500 text-white hover:bg-yellow-600 transition-colors text-xs sm:text-sm"
                    >
                      -
                    </button>
                  </td>
                ))}
              </tr>
            ))}
            <tr className="font-bold border-t-2 border-gray-300 dark:border-gray-600">
              <td className="text-xs md:text-sm">Total</td>
              {stats.map(stat => (
                <td key={`total-${stat}`} className="text-center">
                  <span className="mx-1 sm:mx-2 w-6 inline-block">{totals[stat]}</span>
                </td>
              ))}
            </tr>
            <tr className="border-t-2 border-gray-300 dark:border-gray-600">
              <td className="text-xs md:text-sm font-bold">Opponent Score</td>
              <td colSpan={stats.length} className="text-center">
                <span className="mx-1 sm:mx-2 w-6 inline-block">{opponentScore}</span>
                <button 
                  onClick={() => handleOpponentScoreChange(1)}
                  className="px-3 sm:px-4 py-2 rounded bg-green-500 text-white hover:bg-green-600 transition-colors text-xs sm:text-sm"
                >
                  +
                </button>
                <button 
                  onClick={() => handleOpponentScoreChange(-1)}
                  className="ml-1 sm:ml-2 px-3 sm:px-4 py-2 rounded bg-yellow-500 text-white hover:bg-yellow-600 transition-colors text-xs sm:text-sm"
                >
                  -
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GameStats;
