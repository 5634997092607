import React, { useState } from 'react';

function GameCreator({ onAddGame }) {
  const [gameName, setGameName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (gameName.trim()) {
      onAddGame(gameName);
      setGameName('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 flex flex-col sm:flex-row">
      <input
        type="text"
        value={gameName}
        onChange={(e) => setGameName(e.target.value)}
        placeholder="Enter game name"
        className="px-4 py-2 rounded-t sm:rounded-l sm:rounded-t-none border-2 border-blue-500 focus:outline-none focus:border-blue-600 dark:bg-gray-700 dark:text-white w-full sm:w-auto mb-2 sm:mb-0"
      />
      <button 
        type="submit"
        className="px-4 py-2 rounded-b sm:rounded-r sm:rounded-b-none bg-blue-500 text-white hover:bg-blue-600 transition-colors w-full sm:w-auto"
      >
        Add Game
      </button>
    </form>
  );
}

export default GameCreator;
