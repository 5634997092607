import React, { useState } from 'react';

function PlayerRoster({ players, onAddPlayer, onDeletePlayer }) {
  const [newPlayerName, setNewPlayerName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPlayerName.trim()) {
      onAddPlayer(newPlayerName);
      setNewPlayerName('');
    }
  };

  const handleDeletePlayer = (playerId, playerName) => {
    if (window.confirm(`Are you sure you want to delete ${playerName}? This action cannot be undone.`)) {
      onDeletePlayer(playerId);
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl sm:text-2xl font-bold mb-4">Player Roster</h2>
      <form onSubmit={handleSubmit} className="mb-4 flex flex-col sm:flex-row">
        <input
          type="text"
          value={newPlayerName}
          onChange={(e) => setNewPlayerName(e.target.value)}
          placeholder="Enter player name"
          className="px-4 py-2 rounded-t sm:rounded-l sm:rounded-t-none border-2 border-blue-500 focus:outline-none focus:border-blue-600 dark:bg-gray-700 dark:text-white w-full sm:w-auto mb-2 sm:mb-0"
        />
        <button 
          type="submit"
          className="px-4 py-2 rounded-b sm:rounded-r sm:rounded-b-none bg-blue-500 text-white hover:bg-blue-600 transition-colors w-full sm:w-auto"
        >
          Add Player
        </button>
      </form>
      <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {players.map(player => (
          <li key={player.id} className="flex justify-between items-center bg-white dark:bg-gray-800 p-2 rounded">
            <span className="truncate mr-2">{player.name}</span>
            <button 
              onClick={() => handleDeletePlayer(player.id, player.name)}
              className="px-2 py-1 rounded bg-red-500 text-white hover:bg-red-600 transition-colors text-sm"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PlayerRoster;
