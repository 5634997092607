import React, { useState, useEffect, useCallback } from 'react';
import { usePDF } from 'react-to-pdf';
import './App.css';
import { supabase } from './supabaseClient';
import GameStats from './components/GameStats';
import GameCreator from './components/GameCreator';
import PlayerRoster from './components/PlayerRoster';
import StatsSummary from './components/StatsSummary';
import Login from './components/Login';

function App() {
  const [session, setSession] = useState(null);
  const [games, setGames] = useState([]);
  const [players, setPlayers] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  const { toPDF, targetRef } = usePDF({ filename: 'soccer_stats.pdf' });

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const fetchGames = useCallback(async () => {
    const { data, error } = await supabase
      .from('games')
      .select(`
        *,
        playerStats:player_stats!game_id(
          *,
          player:players(*)
        )
      `)
      .eq('user_id', session?.user.id);
    
    if (error) console.error('Error fetching games:', error);
    else setGames(data);
  }, [session]);

  const fetchPlayers = useCallback(async () => {
    const { data, error } = await supabase
      .from('players')
      .select('*')
      .eq('user_id', session?.user.id);
    
    if (error) console.error('Error fetching players:', error);
    else setPlayers(data);
  }, [session]);

  useEffect(() => {
    if (session) {
      fetchGames();
      fetchPlayers();
    }
  }, [session, fetchGames, fetchPlayers]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const addGame = async (gameName) => {
    const { data: newGame, error: gameError } = await supabase
      .from('games')
      .insert({ name: gameName, user_id: session.user.id })
      .select()
      .single();

    if (gameError) {
      console.error('Error adding game:', gameError);
      return;
    }

    // Create player stats for the new game
    const playerStats = players.map(player => ({
      game_id: newGame.id,
      player_id: player.id,
      goals: 0,
      assists: 0
    }));

    const { error: statsError } = await supabase
      .from('player_stats')
      .insert(playerStats);

    if (statsError) {
      console.error('Error adding player stats:', statsError);
      return;
    }

    // Fetch the newly created game with its player stats
    const { data: gameWithStats, error: fetchError } = await supabase
      .from('games')
      .select(`
        *,
        playerStats:player_stats(
          *,
          player:players(*)
        )
      `)
      .eq('id', newGame.id)
      .single();

    if (fetchError) {
      console.error('Error fetching new game with stats:', fetchError);
      return;
    }

    setGames(prevGames => [...prevGames, gameWithStats]);
  };

  const updateGameStats = async (gameId, playerId, statName, value) => {
    if (statName === 'opponent_score') {
      const { error } = await supabase
        .from('games')
        .update({ opponent_score: value })
        .eq('id', gameId);

      if (error) console.error('Error updating opponent score:', error);
      else {
        setGames(prevGames => prevGames.map(game => 
          game.id === gameId 
            ? { ...game, opponent_score: value }
            : game
        ));
      }
    } else {
      // Existing code for updating player stats
      const { error } = await supabase
        .from('player_stats')
        .update({ [statName]: value })
        .eq('game_id', gameId)
        .eq('player_id', playerId);

      if (error) console.error('Error updating game stats:', error);
      else {
        setGames(prevGames => prevGames.map(game => 
          game.id === gameId 
            ? {
                ...game,
                playerStats: game.playerStats.map(playerStat => 
                  playerStat.player_id === playerId
                    ? { ...playerStat, [statName]: value }
                    : playerStat
                )
              }
            : game
        ));
      }
    }
  };

  const deleteGame = async (gameId) => {
    const { error } = await supabase
      .from('games')
      .delete()
      .eq('id', gameId);
    
    if (error) console.error('Error deleting game:', error);
    else setGames(prevGames => prevGames.filter(game => game.id !== gameId));
  };

  const addPlayer = async (playerName) => {
    const { data: newPlayer, error: playerError } = await supabase
      .from('players')
      .insert({ name: playerName, user_id: session.user.id })
      .select()
      .single();
    
    if (playerError) {
      console.error('Error adding player:', playerError);
      return;
    }

    setPlayers(prevPlayers => [...prevPlayers, newPlayer]);

    // Add player stats for all existing games
    const playerStats = games.map(game => ({
      game_id: game.id,
      player_id: newPlayer.id,
      goals: 0,
      assists: 0
    }));

    const { error: statsError } = await supabase
      .from('player_stats')
      .insert(playerStats);

    if (statsError) {
      console.error('Error adding player stats:', statsError);
      return;
    }

    // Update the games state to include the new player's stats
    setGames(prevGames => prevGames.map(game => ({
      ...game,
      playerStats: [
        ...game.playerStats,
        {
          game_id: game.id,
          player_id: newPlayer.id,
          goals: 0,
          assists: 0,
          player: newPlayer
        }
      ]
    })));
  };

  const deletePlayer = async (playerId) => {
    const { error } = await supabase
      .from('players')
      .delete()
      .eq('id', playerId);
    
    if (error) console.error('Error deleting player:', error);
    else {
      setPlayers(prevPlayers => prevPlayers.filter(player => player.id !== playerId));
      // Remove player stats for all games
      await supabase
        .from('player_stats')
        .delete()
        .eq('player_id', playerId);
    }
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error logging out:', error);
  };

  if (!session) {
    return <Login />;
  }

  return (
    <div ref={targetRef} className={`min-h-screen p-4 sm:p-8 ${darkMode ? 'dark bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="max-w-full sm:max-w-3xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
          <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-0">Soccer Stat Tracker</h1>
          <div className="flex flex-wrap justify-center sm:justify-end gap-2">
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="px-3 py-1 sm:px-4 sm:py-2 rounded bg-blue-500 text-white hover:bg-blue-600 transition-colors text-sm sm:text-base"
            >
              {darkMode ? 'Light Mode' : 'Dark Mode'}
            </button>
            <button
              onClick={() => toPDF()}
              className="px-3 py-1 sm:px-4 sm:py-2 rounded bg-green-500 text-white hover:bg-green-600 transition-colors text-sm sm:text-base"
            >
              Export All to PDF
            </button>
            <button
              onClick={handleLogout}
              className="px-3 py-1 sm:px-4 sm:py-2 rounded bg-red-500 text-white hover:bg-red-600 transition-colors text-sm sm:text-base"
            >
              Logout
            </button>
          </div>
        </div>
        <PlayerRoster players={players} onAddPlayer={addPlayer} onDeletePlayer={deletePlayer} />
        <GameCreator onAddGame={addGame} />
        <StatsSummary players={players} games={games} />
        {games.map(game => (
          <GameStats 
            key={game.id} 
            game={game} 
            onUpdateStats={updateGameStats} 
            onDeleteGame={deleteGame}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
